import './App.css';
import { React, useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Nav } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BACKEND_URL, FRONTEND_URL } from './config';


function Searchbar({ spToken, refresh, currentArtistType }) {

    const [spotifyArtists, setSpotifyArtists] = useState([])
    const [currentSearch, setCurrentSearch] = useState("")
    const [inputField, setInputField] = useState("")
    const [resetting, setResetting] = useState(false)
    const timerRef = useRef(null);


    // const freshestURL = "http://localhost:5000/getArtists"
    // const artist_ids = ["0DdDnziut7wOo6cAYWVZC5", "6RHKEd9dpzQ4c09x8Zdaxu", "7muzHifhMdnfN1xncRLOqk", "1mU61l2mcjEFraXZLpvVMo", "4VIchDxh1kiaM8hiYZ86zS", "2cRyhoIiClcoFycvSUgmdD", "4srikj7R58tanh2S0FpoVw", "1k78cRCnTM3T7EhvhVeaKX", "3XXxju2VQjoFdzGt1QHDYa", "521qvhdobR0GzhvU6TFw76", "6EaYAFQr1djVNaKw1i5TUb"]

    useEffect(() => {
        searchSpotifyArtists();
        setResetting(false)
    }, [currentSearch])

    useEffect(()=>{
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setResetting(true)
            setCurrentSearch(inputField);
        }, 50);
    },[inputField]);

    const searchSpotifyArtists = async () => {
        if (currentSearch && spToken) {
            const { data } = await axios.get("https://api.spotify.com/v1/search", {
                headers: {
                    Authorization: `Bearer ${spToken}`
                },
                params: {
                    type: "artist",
                    q: currentSearch,
                    limit: 5
                }
            })
            // data.artists.map((spotify_artist, index) => { spotify_artist.freshest = freshestArtists[index] })
            setSpotifyArtists(data.artists.items)
        } else {
            setSpotifyArtists([])
        }
    }

    // const updateArtist = async (index, update) => {
    //     let artist_id = freshestArtists[index].id;
    //     update = { ...update, "id": artist_id };
    //     const { data } = await axios.patch("http://localhost:5000/updateArtist", update
    //     )
    //     if (data.type != current_artist_type) {
    //         let myFreshest = [...freshestArtists];
    //         let mySpotify = [...spotifyArtists];
    //         myFreshest.splice(index, 1);
    //         mySpotify.splice(index, 1);
    //         setFreshestArtists(myFreshest);
    //         setSpotifyArtists(mySpotify);
    //     } else {
    //         let myFreshest = [...freshestArtists];
    //         myFreshest[index] = data;
    //         setFreshestArtists(myFreshest);
    //     }
    //     // freshestArtist.popularity += 1;
    //     // setSpotifyArtists(spotifyArtists)
    //     // axios.get("https://api.spotify.com/v1/artists");

    // }

    // const logout = () => {
    //     setToken("")
    //     window.localStorage.removeItem("token")
    // }

    const addArtist = async (artist) => {
        artist = Object.assign({}, artist, { "type": currentArtistType })
        let wrap = { "spotify_artist": artist }
        const { data } = await axios.post(BACKEND_URL+"addArtist", wrap
        )
        if (data) {
            resetSearch()
        }
    }

    const renderArtist = () => {
        return spotifyArtists.map((artist, index) => {
            let imageUri = artist.images.length ? artist.images[0].url : "https://via.placeholder.com/150";
            return (
                <div className="artist-search-container" key={artist.id + "search"}>
                    <div className="artist-search-image-container">
                        <img className="artist-search-image" width={"100%"} src={imageUri} alt="" />
                    </div>
                    <div className="artist-serach-info">
                        <div className="artist-search-name">
                            {artist.name}
                        </div>
                    </div>
                    <div className="artist-search-add-artist" onClick={() => addArtist(artist)}>
                        ＋
                    </div>
                </div>)
        })
    }

    const resetSearch = () => {
        setCurrentSearch("");
        setSpotifyArtists([]);
        setInputField("");
    }

    return (
        <div className='artist-search'>
            <input className="artist-search-input" onChange={(e) => {
                setInputField(e.target.value)
            }}
            value={inputField}></input>
            <div className='artist-search-results'>
                {renderArtist()}
            </div>
            {spotifyArtists.length > 0 ? <div className='blocker' onClick={resetSearch}></div> : <div />}
        </div>
    );
}

export default Searchbar;