import './App.css';
import { React, useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Nav } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import Searchbar from './Searchbar';
import { BACKEND_URL, FRONTEND_URL } from './config';

function App() {
  const CLIENT_ID = "fa7c2512736245b39ad00f229f79b4b9"
  const REDIRECT_URI = FRONTEND_URL
  const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
  const RESPONSE_TYPE = "token"

  const [spToken, setToken] = useState("")
  const [current_artist_type, setCurrentArtistType] = useState("related")
  const [freshestArtists, setFreshestArtists] = useState([])
  const [spotifyArtists, setSpotifyArtists] = useState([])
  const [nextUrl, setNextUrl] = useState("")
  const [resetting, setResetting] = useState(false)
  const [loadingFreshest, setLoadingFreshest] = useState(false)
  const [currentSearch, setCurrentSearch] = useState("")
  const timerRef = useRef(null);
  // const freshestURL = "http://localhost:5000/getArtists"
  // const artist_ids = ["0DdDnziut7wOo6cAYWVZC5", "6RHKEd9dpzQ4c09x8Zdaxu", "7muzHifhMdnfN1xncRLOqk", "1mU61l2mcjEFraXZLpvVMo", "4VIchDxh1kiaM8hiYZ86zS", "2cRyhoIiClcoFycvSUgmdD", "4srikj7R58tanh2S0FpoVw", "1k78cRCnTM3T7EhvhVeaKX", "3XXxju2VQjoFdzGt1QHDYa", "521qvhdobR0GzhvU6TFw76", "6EaYAFQr1djVNaKw1i5TUb"]

  useEffect(() => {
    const hash = window.location.hash
    let myToken = window.localStorage.getItem("token")
    let tokenExpiry = window.localStorage.getItem("tokenExpiry")
    let expiryDate = new Date(tokenExpiry);
    let currentDate = new Date();

    if (hash && hash.includes("access_token")) {
      myToken = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]
      let expires_in = hash.substring(1).split("&").find(elem => elem.startsWith("expires_in")).split("=")[1]

      var expiry_date = new Date();
      expiry_date.setSeconds(expiry_date.getSeconds() + (expires_in - 2));
      let json_date = expiry_date.toJSON();

      window.location.hash = ""
      window.localStorage.setItem("token", myToken)
      window.localStorage.setItem("tokenExpiry", json_date)
      setTimeout(getNewToken, (expires_in - 10) * 1000)
    } else if (!myToken || (myToken && currentDate > expiryDate)) {
      getNewToken()
    }
    console.log('i fire once');
    setToken(myToken)
  }, [])

  const getNewToken = () => {
    window.location.replace(`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}`);
  }

  useEffect(() => {
    if (spToken && !loadingFreshest) {
      let myOffset = spotifyArtists.length;
      searchSpotifyArtists(freshestArtists.slice(myOffset).map((artist) => artist.id))
    }
  }, [spToken, loadingFreshest])

  useEffect(() => {
    setNextUrl("")
    setSpotifyArtists([])
    setFreshestArtists([])
    setResetting(false)
  }, [current_artist_type, currentSearch])

  const refresh = (() => {
    setResetting(true);
    timerRef.current = setTimeout(() => {
      setNextUrl("")
      setSpotifyArtists([])
      setFreshestArtists([])
      setResetting(false);
    }, 50);
  })

  useEffect(() => {
    if (!resetting) {
      getFreshestArtists();
    }
  }, [resetting])

  const getFreshestArtists = async () => {
    setLoadingFreshest(true)
    let data;
    if (nextUrl) {
      ({ data } = await axios.get(nextUrl))
      setFreshestArtists(freshestArtists.concat(data.items))
    } else {
      ({ data } = await axios.get(BACKEND_URL + "getArtists", { params: { "artist_type": current_artist_type, "search": currentSearch } }))
      setFreshestArtists(data.items)
    }
    setNextUrl(data.next)
    setLoadingFreshest(false)
  }

  const searchSpotifyArtists = async (artist_ids) => {
    try {
      if (artist_ids.length > 0) {
        const { data } = await axios.get("https://api.spotify.com/v1/artists", {
          headers: {
            Authorization: `Bearer ${spToken}`
          },
          params: {
            ids: artist_ids.join(",")
          }
        });
        setSpotifyArtists(spotifyArtists.concat(data.artists));
      } else {
        setSpotifyArtists([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data.error.message;
        if (errorMessage.toLowerCase().includes("token")) {
          // Token-related error, possibly expired or invalid
          getNewToken();
          // Retry the request with the new token
          // await searchSpotifyArtists(artist_ids);
        } else {
          console.error("Authorization error:", errorMessage);
        }
      } else {
        console.error("An error occurred:", error);
      }
    }
  };


  const updateArtist = async (index, update) => {
    let artist_id = freshestArtists[index].id;
    update = { ...update, "id": artist_id };
    const { data } = await axios.patch(BACKEND_URL + "updateArtist", update
    )
    if (data.type != current_artist_type) {
      let myFreshest = [...freshestArtists];
      let mySpotify = [...spotifyArtists];
      myFreshest.splice(index, 1);
      mySpotify.splice(index, 1);
      setFreshestArtists(myFreshest);
      setSpotifyArtists(mySpotify);
    } else {
      let myFreshest = [...freshestArtists];
      myFreshest[index] = data;
      setFreshestArtists(myFreshest);
    }
    // freshestArtist.popularity += 1;
    // setSpotifyArtists(spotifyArtists)
    // axios.get("https://api.spotify.com/v1/artists");

  }

  const logout = () => {
    setToken("")
    window.localStorage.removeItem("token")
  }

  const renderSpotifyArtists = () => {
    // if (spotifyArtists.length == freshestArtists.length) {
    return spotifyArtists.slice(0, freshestArtists.length).map((artist, index) => {
      let imageUri = artist.images.length ? artist.images[0].url : "https://via.placeholder.com/150";
      return (
        <div className="artist-card" key={artist.id}>
          <div className="image-container">
            <div className="popularity-bar">
              <div className="popularity-up" onClick={() => updateArtist(index, { popularity: freshestArtists[index].popularity + 1 })}>⬆️</div>
              <div>🔥</div>
              <div className="popularity-down" onClick={() => updateArtist(index, { popularity: freshestArtists[index].popularity - 1 })}>⬇️</div>
            </div>
            <img className="artist-image" width={"100%"} src={imageUri} alt="" />
            <div className="action-bar">
              <div className="whitelist" onClick={() => updateArtist(index, { type: "whitelist" })}>⚪📋</div>
              <div className="related" onClick={() => updateArtist(index, { type: "related" })}>🧑‍🤝‍🧑</div>
              <div className="blacklist" onClick={() => updateArtist(index, { type: "blacklist" })}>⚫📋</div>
            </div>
          </div>
          <div className="artist-card-bottom">
            <div className="artist-name">
              {artist.name}
            </div>
            <div className="artist-info">
              <div className="popularity">🔥{freshestArtists[index].popularity}</div>
              <div className="references">🤝{freshestArtists[index].references}</div>
            </div>
          </div>
        </div>)
    })
    // } else {
    //   return <div />
    // }
  }

  return (
    <div className="App">
      <header className="App-header">
        {!spToken ?
          <a href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}`}>Login
            to Spotify</a>
          : <button onClick={logout}>Logout</button>}
      </header>
      <button onClick={refresh}>Refresh</button>
      <Searchbar spToken={spToken} refresh={refresh} currentArtistType={current_artist_type}></Searchbar>
      <Nav
        variant="tabs"
        defaultActiveKey="related"
        onSelect={(selectedKey) => {
          setResetting(true);
          setCurrentArtistType(selectedKey);
        }}
      >
        <Nav.Item>
          <Nav.Link eventKey="related">Related</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="whitelist">Whitelist</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="blacklist">Blacklist</Nav.Link>
        </Nav.Item>
        <div className='filter'>
          Filter:&nbsp;
          <input onChange={(e) => {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
              setResetting(true);
              setCurrentSearch(e.target.value);
            }, 50);
          }}></input>
        </div>
      </Nav>
      {/* <div className="grid-parent"> */}
      <InfiniteScroll
        className="grid-parent"
        dataLength={spotifyArtists.length} //This is important field to render the next data
        next={getFreshestArtists}
        hasMore={!!nextUrl}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      // below props only if you need pull down functionality
      // refreshFunction={this.refresh}
      // pullDownToRefresh
      // pullDownToRefreshThreshold={50}
      // pullDownToRefreshContent={
      //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
      // }
      // releaseToRefreshContent={
      //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
      // }
      >
        {renderSpotifyArtists()}
      </InfiniteScroll>
      {/* {spotifyArtists ? renderSpotifyArtists() : ""} */}
      {/* </div> */}
    </div>
  );
}

export default App;
